import React, { useMemo, useState } from 'react';
import { ButtonTabNavHorizontal, Heading } from '../../../components';
import { useIntl } from 'react-intl';
import SectionReviews from '../SectionReviews';
import CommentSection from '../CommentSection/CommentSection';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import css from './CommentsAndReviews.module.css';
import listingCss from '../ListingPage.module.css';

const TABS = {
  COMMENTS: 'comments',
  REVIEWS: 'reviews',
};

const CommentsAndReviews = props => {
  const {
    listingId,
    isOwnListing,
    areCommentsAllowed,
    isListingClosed,
    reviews,
    fetchReviewsError,
    fetchReviewsInProgress,
  } = props;

  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState(TABS.COMMENTS);
  const [totalComments, setTotalComments] = useState(0);

  const { reviewsPagination } = useSelector(state => state.ListingPage);

  const tabs = useMemo(
    () => [
      {
        text: (
          <Heading
            as="h2"
            rootClassName={classNames(listingCss.sectionHeadingWithExtraMargin, css.tabTitle)}
          >
            {`${intl.formatMessage({
              id: 'CommentSection.title',
            })} (${totalComments})`}
          </Heading>
        ),
        selected: selectedTab === TABS.COMMENTS,
        onClick: () => setSelectedTab(TABS.COMMENTS),
      },
      {
        text: (
          <Heading
            as="h2"
            rootClassName={classNames(listingCss.sectionHeadingWithExtraMargin, css.tabTitle)}
          >
            {intl.formatMessage(
              { id: 'ListingPage.reviewsTitle' },
              { count: reviewsPagination?.totalItems || 0 }
            )}
          </Heading>
        ),
        selected: selectedTab === TABS.REVIEWS,
        onClick: () => setSelectedTab(TABS.REVIEWS),
      },
    ],
    [intl, reviewsPagination, selectedTab, totalComments]
  );

  return (
    <section className={classNames(css.root, listingCss.sectionReviews)}>
      <ButtonTabNavHorizontal tabs={tabs} tabRootClassName={css.tab} />

      {selectedTab === TABS.COMMENTS ? (
        <CommentSection
          listingId={listingId}
          isOwnListing={isOwnListing}
          areCommentsAllowed={areCommentsAllowed}
          isListingClosed={isListingClosed}
          onTotalItemsChange={setTotalComments}
        />
      ) : (
        <SectionReviews
          reviews={reviews}
          fetchReviewsError={fetchReviewsError}
          fetchReviewsInProgress={fetchReviewsInProgress}
        />
      )}
    </section>
  );
};

export default CommentsAndReviews;
