import React, { useCallback, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, Form, NamedLink, PrimaryButton } from '../../../components';
import { useIntl } from 'react-intl';
import api from '../../../api';
import { useLocation } from 'react-router-dom';

import css from './CommentSection.module.css';

const KEY_CODE_ENTER = 13;

const CommentForm = props => {
  const { disabled, listingId, onSubmitSuccess, isAuthenticated } = props;

  const intl = useIntl();
  const location = useLocation();
  const [isSendingComment, setIsSendingComment] = useState(false);

  const onKeyDown = useCallback((event, formApi) => {
    if (event.shiftKey) return;

    if (event.keyCode === KEY_CODE_ENTER || event.code === 'Enter') {
      formApi.submit();
    }
  }, []);

  const onSubmitComment = useCallback(
    async (values, formApi) => {
      const { comment, isFollowing } = values;

      try {
        setIsSendingComment(true);

        const { data } = await api.listings.createComment(
          listingId,
          comment.trim(),
          isFollowing?.length > 0
        );

        onSubmitSuccess(data);

        formApi.batch(() => {
          formApi.change('comment', null);
          formApi.resetFieldState('comment');
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsSendingComment(false);
      }
    },
    [listingId, onSubmitSuccess]
  );

  return (
    <FinalForm
      onSubmit={onSubmitComment}
      render={formRenderProps => {
        const { handleSubmit, invalid, form, values } = formRenderProps;

        const { comment } = values;

        const hasCommentInInput = !!comment?.trim();

        const submitDisabled = invalid || isSendingComment || disabled || !hasCommentInInput;

        return (
          <Form onSubmit={handleSubmit}>
            <FieldTextInput
              type="textarea"
              id="comment"
              name="comment"
              maxLength={1600}
              placeholder={intl.formatMessage({ id: 'CommentSection.inputPlaceholder' })}
              className={css.commentInput}
              disabled={disabled}
              onKeyDown={e => !submitDisabled && onKeyDown(e, form)}
            />

            {!isAuthenticated ? (
              <div>
                {intl.formatMessage(
                  { id: 'CommentSection.notAuth' },
                  {
                    loginLink: (
                      <NamedLink
                        name="LoginPage"
                        to={{
                          state: {
                            from: `${location.pathname}${location.search}${location.hash}`,
                          },
                        }}
                      >
                        <b>{intl.formatMessage({ id: 'TopbarDesktop.login' }).toLowerCase()}</b>
                      </NamedLink>
                    ),
                  }
                )}
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                disabled={submitDisabled}
                inProgress={isSendingComment}
                className={css.send}
              >
                {intl.formatMessage({ id: 'CommentSection.send' })}
              </PrimaryButton>
            )}
          </Form>
        );
      }}
    />
  );
};

export default CommentForm;
