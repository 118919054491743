import React from 'react';
import { propTypes } from '../../../util/types';
import { string } from 'prop-types';
import { richText } from '../../../util/richText';

import css from './ActivityFeed.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const OwnMessage = props => {
  const { message, formattedDate, youMsg } = props;
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>
          {richText(message.attributes.content, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
            linkClass: css.messageLink,
            linkify: true,
          })}
        </p>
      </div>
      <p className={css.ownMessageDate}>
        {youMsg ? <span className={css.ownMessageYou}>{youMsg}</span> : null}

        {formattedDate}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  formattedDate: string.isRequired,
  youMsg: string,
};

export default OwnMessage;
