import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FieldCheckbox, Form, SecondaryButtonInline } from '../../../components';
import { useIntl } from 'react-intl';
import listingPageCss from '../ListingPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import api from '../../../api';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import CommentForm from './CommentForm';
import debounce from 'lodash/debounce';
import Comments from './Comments';

import css from './CommentSection.module.css';

const CommentSection = props => {
  const {
    className,
    listingId,
    isOwnListing,
    areCommentsAllowed,
    isListingClosed,
    onTotalItemsChange,
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();
  const [comments, setComments] = useState([]);
  const [commentsPaginationKey, setCommentsPaginationKey] = useState(null);
  const [isFetchingComments, setIsFetchingComments] = useState(false);
  const [isTogglingNotif, setIsTogglingNotif] = useState(false);
  const [isTogglingComments, setIsTogglingComments] = useState(false);

  const {
    auth: { isAuthenticated },
    user: { currentUser },
  } = useSelector(({ user, auth }) => ({ auth, user }));

  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};
  const { followingListingDiscussions } = metadata || {};

  const { totalItems } = commentsPaginationKey || {};

  const classes = classNames(css.root, className);

  const initialNotificationValues = useMemo(
    () => ({
      isFollowing: (followingListingDiscussions || []).includes(listingId) ? ['true'] : undefined,
    }),
    [followingListingDiscussions, listingId]
  );

  const showForm = areCommentsAllowed && !isListingClosed;

  const isEverythingDisabled = !isAuthenticated || !listingId || isFetchingComments || !showForm;

  const updateListing = useCallback(
    data => (dispatch, getState, sdk) => {
      const { id } = getState().ListingPage;

      setIsTogglingComments(true);

      return sdk.ownListings
        .update(
          { id, ...data },
          {
            expand: true,
            include: ['publicData'],
          }
        )
        .then(data => {
          // IMPORTANT: This is quite hacky and shouldn't be done
          // unless you know that the data you are updating is publically available;
          // So we don't show 'ownListing' data to public users.
          data.data.data.type = 'listing';

          dispatch(addMarketplaceEntities(data));

          return data;
        })
        .catch(e => {})
        .finally(() => {
          setIsTogglingComments(false);
        });
    },
    []
  );

  const toggleEnableComments = useCallback(() => {
    dispatch(
      updateListing({
        publicData: {
          areCommentsAllowed: !areCommentsAllowed,
        },
      })
    );
  }, [areCommentsAllowed, dispatch, updateListing]);

  const toggleEmailNotifications = useMemo(
    () =>
      debounce(async values => {
        const { isFollowing } = values;

        try {
          setIsTogglingNotif(true);

          const followFn =
            isFollowing?.length > 0
              ? api.listings.followCommentDiscussion
              : api.listings.unfollowCommentDiscussion;

          await followFn(listingId);
        } catch (error) {
          console.error(error);
        } finally {
          setIsTogglingNotif(false);
        }
      }, 500),
    [listingId]
  );

  const loadComments = useCallback(
    async params => {
      try {
        setIsFetchingComments(true);

        const { data } = await api.listings.getComments(listingId, {
          ...params,
          sort: 'createdAt',
        });

        const { items, lastKey, totalItems } = data;

        setComments(old => [...old, ...items]);
        setCommentsPaginationKey({ lastKey, totalItems });
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingComments(false);
      }
    },
    [listingId]
  );

  const loadMoreComments = useCallback(
    lastKey => {
      loadComments({ lastKey, limit: 10 });
    },
    [loadComments]
  );

  const onDeleteCommentSuccess = useCallback(commentId => {
    setComments(old => old.filter(({ _id }) => _id !== commentId));
  }, []);

  const onSubmitCommentSuccess = useCallback(data => {
    setComments(old => [...old, data]);
  }, []);

  useEffect(() => {
    if (!listingId) return;

    loadComments({ limit: 3 });
  }, [listingId, loadComments]);

  useEffect(() => {
    if (!(totalItems >= 0)) return;

    onTotalItemsChange(totalItems);
  }, [onTotalItemsChange, totalItems]);

  return (
    <section className={classes}>
      <div className={css.title}>
        <FinalForm
          onSubmit={() => {}}
          initialValues={initialNotificationValues}
          render={formRenderProps => {
            const { handleSubmit } = formRenderProps;

            return (
              <Form
                onSubmit={handleSubmit}
                className={listingPageCss.sectionHeadingWithExtraMargin}
              >
                <FormSpy
                  subscription={{ values: true, dirty: true }}
                  onChange={({ values, dirty }) => {
                    if (!dirty) return;

                    toggleEmailNotifications(values);
                  }}
                />
                <FieldCheckbox
                  name="isFollowing"
                  id="isFollowing"
                  label={intl.formatMessage({ id: 'CommentSection.notifyForUpdates' })}
                  value="true"
                  disabled={isEverythingDisabled || isTogglingNotif}
                />
              </Form>
            );
          }}
        />
      </div>
      <Comments
        comments={comments}
        currentUser={currentUser}
        listingId={listingId}
        onDeleteSuccess={onDeleteCommentSuccess}
        commentsPagination={commentsPaginationKey}
        disabled={isEverythingDisabled}
        isFetchingComments={isFetchingComments}
        loadMoreComments={loadMoreComments}
      />
      {showForm ? (
        <CommentForm
          listingId={listingId}
          disabled={isEverythingDisabled}
          onSubmitSuccess={onSubmitCommentSuccess}
          isAuthenticated={isAuthenticated}
        />
      ) : (
        <p className={css.commentsDisabled}>
          {intl.formatMessage({ id: 'CommentSection.commentsDisabled' })}
        </p>
      )}
      {isOwnListing && (
        <SecondaryButtonInline
          type="button"
          onClick={toggleEnableComments}
          className={css.allowCommentsCTA}
          disabled={isTogglingComments}
          inProgress={isTogglingComments}
        >
          {!areCommentsAllowed
            ? intl.formatMessage({ id: 'CommentSection.enableComments' })
            : intl.formatMessage({ id: 'CommentSection.disableComments' })}
        </SecondaryButtonInline>
      )}
    </section>
  );
};

CommentSection.propTypes = {
  className: string,
  listingId: string.isRequired,
};

export default CommentSection;
