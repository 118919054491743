import React from 'react';
import { InlineTextButton, Modal, PrimaryButton } from '../../components';
import { bool, func, string } from 'prop-types';

import css from './ConfirmModal.module.css';

export const ConfirmModal = props => {
  const {
    title,
    description,
    confirmationText,
    cancelText,
    showCancelButton,
    confirmInProgress,
    confirmError,
    onConfirm,
    onClose,
    ...restOfProps
  } = props;

  return (
    <Modal
      id="ConfirmModal"
      containerClassName={css.container}
      isOpen
      usePortal
      onClose={onClose}
      {...restOfProps}
    >
      {title && <div className={css.title}>{title}</div>}
      {description && <div className={css.description}>{description}</div>}

      {confirmError && <div className={css.error}>{confirmError}</div>}

      <div className={css.buttons}>
        {showCancelButton && <InlineTextButton onClick={onClose}>{cancelText}</InlineTextButton>}

        <PrimaryButton
          className={css.confirmButton}
          inProgress={confirmInProgress}
          disabled={confirmInProgress}
          onClick={onConfirm}
        >
          {confirmationText}
        </PrimaryButton>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  confirmationText: 'OK',
  cancelText: 'Cancel',
  showCancelButton: true,
};

ConfirmModal.propTypes = {
  title: string,
  description: string,
  confirmationText: string,
  cancelText: string,
  showCancelButton: bool,
  confirmInProgress: bool,
  confirmError: string,
  onConfirm: func,
  onClose: func,
};
