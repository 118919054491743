import ReactDOM from 'react-dom';
import React from 'react';
import { ConfirmModal } from './ConfirmModal';
import { bool, func, string } from 'prop-types';

const Confirm = config => {
  const { onConfirm, onClose, ...rest } = config;

  const div = document.createElement('div');

  const portalRoot = document.getElementById('portal-root');
  portalRoot.appendChild(div);

  const unmount = callback => {
    ReactDOM.unmountComponentAtNode(div);
    portalRoot.removeChild(div);
    callback();
  };

  return new Promise(resolve => {
    ReactDOM.render(
      <ConfirmModal
        onClose={() => {
          onClose?.();
          unmount(() => resolve(false));
        }}
        onConfirm={async () => {
          await onConfirm?.();
          unmount(() => resolve(true));
        }}
        {...rest}
      />,
      div
    );
  });
};

Confirm.propTypes = {
  title: string,
  description: string,
  confirmationText: string,
  cancelText: string,
  showCancelButton: bool,
  confirmInProgress: bool,
  confirmError: string,
  onConfirm: func,
  onClose: func,
};

export default Confirm;
