import React, { useCallback } from 'react';
import { FieldTextInput, Form, H2, Modal, PrimaryButton } from '../../components';
import { parse, stringify } from '../../util/urlHelpers';
import { useLocation, useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';

import css from './ListingPage.module.css';

const OfferListingModal = props => {
  const {
    onManageDisableScrolling,
    onSubmit,
    authorDisplayName,
    title,

    offerListingInProgress,
    offerListingError,
  } = props;
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const isOpen = !!parse(location.search).offerListingOpen;

  const onCloseModal = useCallback(() => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'offerListingOpen');
    const searchString = `?${stringify(searchParams)}`;
    history.push(`${pathname}${searchString}`, state);
  }, [history, location]);

  return (
    <Modal
      id="OfferListingModal"
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <FinalForm
        onSubmit={onSubmit}
        render={formRenderProps => {
          const { handleSubmit, values } = formRenderProps;

          const { initialMessage } = values;

          return (
            <Form onSubmit={handleSubmit}>
              <H2 as="h1" className={css.offerListingTitle}>
                {title}
              </H2>

              <FieldTextInput
                label={intl.formatMessage(
                  { id: 'InquiryForm.messageLabel' },
                  { authorDisplayName }
                )}
                placeholder={intl.formatMessage(
                  { id: 'ListingPage.offerListingPlaceholder' },
                  { authorDisplayName }
                )}
                maxLength="1000"
                type="textarea"
                name="initialMessage"
                id="initialMessage"
                className={css.offerListingInput}
              />

              {offerListingError ? (
                <div className={css.errorText}>{intl.formatMessage({ id: 'General.error' })}</div>
              ) : null}

              <PrimaryButton
                type="submit"
                disabled={!initialMessage?.trim() || offerListingInProgress}
                inProgress={offerListingInProgress}
              >
                {intl.formatMessage({ id: 'OrderPanel.ctaButtonMessagePlaceAnOffer' })}
              </PrimaryButton>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default OfferListingModal;
